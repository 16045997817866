/* ==============
  Calendar
===================*/

.lnb-calendars-item {
    display: inline-block;
    margin-right: 7px
        /*rtl: margin-left: 7px*/
    ;
}

input[type="checkbox"].tui-full-calendar-checkbox-round+span {
    margin-right: 4px
        /*rtl: 0*/
    ;
    margin-left: 0
        /*rtl: 4px*/
    ;
}

.tui-full-calendar-layout,
.tui-full-calendar-timegrid-timezone {
    background-color: var(--#{$prefix}secondary-bg) !important;
}

.tui-full-calendar-dayname-container,
.tui-full-calendar-left,
.tui-full-calendar-splitter,
.tui-full-calendar-time-date,
.tui-full-calendar-weekday-grid-line,
.tui-full-calendar-timegrid-timezone,
.tui-full-calendar-timegrid-gridline {
    border-color: var(--#{$prefix}border-color) !important;
}

.tui-full-calendar-weekday-exceed-in-week {
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 28px;
    border-radius: 4px;
    background-color: var(--#{$prefix}custom-white);
    color: var(--#{$prefix}body-color);
    border-color: var(--#{$prefix}border-color);
}

.tui-full-calendar-timegrid-hour {
    color: var(--#{$prefix}body-color) !important;
}

.tui-full-calendar-weekday-schedule-title {
    color: var(--#{$prefix}emphasis-color) !important;

    .tui-full-calendar-time-schedule {
        font-weight: $font-weight-semibold;
    }
}

.tui-full-calendar-popup-container {
    background-color: var(--#{$prefix}custom-white) !important;
    border-color: var(--#{$prefix}border-color) !important;
}

.tui-full-calendar-dropdown {
    &:hover {
        .tui-full-calendar-dropdown-button {
            border-color: var(--#{$prefix}border-color)
        }
    }
}

.tui-full-calendar-popup-section-item {
    &:hover, &:focus {
        border-color: var(--#{$prefix}border-color);
    }
}

.tui-full-calendar-arrow-bottom .tui-full-calendar-popup-arrow-fill {
    border-top-color: var(--#{$prefix}border-color) !important;
}

.tui-full-calendar-arrow-top .tui-full-calendar-popup-arrow-fill {
    border-bottom-color: var(--#{$prefix}border-color) !important;
}

.tui-full-calendar-arrow-bottom .tui-full-calendar-popup-arrow-borde {
    border-bottom-color: var(--#{$prefix}border-color) !important;
}

.tui-full-calendar-button {
    color: $input-color;
    background-color: $input-bg  !important;
    border-color: var(--#{$prefix}border-color);
}

.tui-full-calendar-popup-section-item {
    border-color: var(--#{$prefix}border-color);
}

.tui-full-calendar-month-dayname,
.tui-full-calendar-weekday-border {
    border-top-color: var(--#{$prefix}border-color) !important;
}

.tui-full-calendar-arrow-top .tui-full-calendar-popup-arrow-border {
    border-bottom-color: var(--#{$prefix}border-color) !important;
}

.tui-full-calendar-dropdown-menu {
    border-color: var(--#{$prefix}border-color) !important;
}

.tui-full-calendar-dropdown-menu-item,
.tui-full-calendar-dropdown-menu {
    background-color: $input-bg  !important;
}

.tui-full-calendar-arrow-bottom .tui-full-calendar-popup-arrow-border {
    border-top-color: var(--#{$prefix}border-color);
}

.tui-full-calendar-content {
    background-color: $input-bg  !important;
    color: $input-color  !important;
}

.tui-full-calendar-confirm {
    background-color: $danger  !important;
    color: $white  !important;

    &:hover {
        background-color: $red-600  !important;
        color: $white  !important;
    }
}

.tui-full-calendar-month-dayname-item {
    span {
        color: var(--#{$prefix}emphasis-color) !important;
    }
}

.tui-full-calendar-weekday-grid-line {
    &.tui-full-calendar-near-month-day {
        &.tui-full-calendar-extra-date {
            .tui-full-calendar-weekday-grid-header {
                .tui-full-calendar-weekday-grid-date {
                    color: var(--#{$prefix}secondary-color) !important;
                }
            }
        }
    }
}


.tui-full-calendar-weekday-grid-line {
    &.tui-full-calendar-near-month-day {
        .tui-full-calendar-weekday-grid-header {
            .tui-full-calendar-weekday-grid-date {
                color: var(--#{$prefix}emphasis-color) !important;
            }
        }
    }
}