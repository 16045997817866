@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.hevara-payment-setting-list-item {
  display: grid;
  grid-template-columns: auto max-content;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .status {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .status-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .status-icon {
      width: auto;

      @media (min-width: 768px) {
        width: 20px;
      }
    }

    .status-chip {
      margin-left: 8px;

      @media (min-width: 768px) {
        margin-left: 64px;
      }
    }
  }

  .hide-element {
    visibility: hidden;
  }

  .hevara-chip {
    margin-top: 2px;

    @media (min-width: 768px) {
      margin-top: 0px;
    }
  }

  .hevara-table-row-info {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
  }

  .feature-actions {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  .hevara-table-row-main-info {
    margin-right: 16px;

    &> :first-child {
      @include truncate;
    }
  }

  .hevara-table-row-info {
    margin-right: 16px;
    @include truncate;
  }

  .hevara-table-row-main-info-title {
    @include truncate;
  }

  .reset-link:hover {
    text-decoration: none;
  }
}