/* ==============
  Calendar
===================*/


.fc td,
.fc th {
  border: $table-border-width solid $table-border-color;
}




.fc {
  .fc-toolbar {
      h2 {
          font-size: 16px;
          line-height: 30px;
          text-transform: uppercase;
      }

      @media (max-width: 767.98px) {

          .fc-left,
          .fc-right,
          .fc-center {
              float: none;
              display: block;
              text-align: center;
              clear: both;
              margin: 10px 0;
          }

          >*>* {
              float: none;
          }

          .fc-today-button {
              display: none;
          }
      }

      .btn {
          text-transform: capitalize;
      }

  }
  .fc-col-header-cell-cushion {
    color: var(--#{$prefix}body-color);
  }

  .fc-daygrid-day-number {
    color: var(--#{$prefix}body-color);
  }
}

.fc-daygrid-event-dot {
  display: none;
}

.fc-prev-button,
.fc-next-button {
  position: relative;
  padding: 6px 8px !important;
}


.fc-toolbar-chunk {
  .fc-button-group {
    .fc-button {
      background-color: var(--#{$prefix}primary);
      border-color: var(--#{$prefix}primary);
      ;
      box-shadow: none;

      &:hover,
      &.active {
        color: $white;
        background-color: var(--#{$prefix}primary);
        border-color: var(--#{$prefix}primary);
      }
    }
  }

  .fc-today-button {
    background-color: var(--#{$prefix}primary) !important;
    border-color: var(--#{$prefix}primary) !important;
  }
}

.fc {

  .fc-button-primary:not(:disabled).fc-button-active,
  .fc-button-primary:not(:disabled):active {
    background-color: var(--#{$prefix}primary) !important;
    border-color: var(--#{$prefix}primary) !important;
    color: $white !important;
  }
}

.fc-toolbar {
  @media (max-width: 575.98px) {
    flex-direction: column;
    gap: 16px;
  }
}

.fc {
  th.fc-widget-header {
      background: $gray-300;
      color: $gray-700;
      line-height: 20px;
      padding: 10px 0;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
  }
}

.fc-unthemed {

  .fc-content,
  .fc-divider,
  .fc-list-heading td,
  .fc-list-view,
  .fc-popover,
  .fc-row,
  tbody,
  td,
  th,
  thead {
      border-color: $gray-300;
  }

  td.fc-today {
      background: lighten($gray-200, 2%);
  }
}

.fc-button {
  background: var(--#{$prefix}secondary-bg);
  border-color: var(--#{$prefix}border-color);
  color: $gray-700;
  text-transform: capitalize;
  box-shadow: none;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: $primary;
  color: $white;
  text-shadow: none;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
  color: $white;

  &.bg-dark {
    .fc-event-time {
      color: var(--#{$prefix}secondary-bg) !important;
    }
    background-color: var(--#{$prefix}secondary-color) !important;
    .fc-event-title {
      color: var(--#{$prefix}secondary-bg) !important;
    }
  }
}

.fc-event,
.fc-event-dot {
  background-color: $primary;
}

.fc-event .fc-content {
  color: $white;
}

#external-events {
  .external-event {
      text-align: left;
      padding: 8px 16px;
      margin-left: 0;
      margin-right: 0;
  }
}


.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark {
  .fc-content{
    color: $light;  
  }
}


// RTL
[dir="rtl"]{
  .fc-header-toolbar {
      direction: ltr !important;
  }

  .fc-toolbar>*>:not(:first-child) {
      margin-left: .75em;
  }
}

.fc-theme-standard .fc-scrollgrid {
  border-color: var(--#{$prefix}border-color);
}

.fc .fc-daygrid-week-number {
  background-color: var(--#{$prefix}body-bg);
  color: var(--#{$prefix}body-color);
}

.fc .fc-daygrid-more-link {
  padding: 5px;
  font-size: 11px;
  font-weight: 600;
}

.fc .fc-daygrid-more-link:hover {
  background-color: rgba(var(--#{$prefix}primary-rgb), 0.1);
}

.fc .fc-popover-header {
  padding: 6px 12px;
}

.fc-theme-standard .fc-popover-header {
  background: var(--#{$prefix}body-bg);
}

.fc-theme-standard .fc-popover {
    background: var(--#{$prefix}secondary-bg);
    border: 1px solid var(--#{$prefix}border-color);
}
