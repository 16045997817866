.hevara-product-detail {
  width: 100%;

  .tx-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    @media (min-width: 992px) {
      grid-template-columns: 2fr 1fr;
    }

    .detail-grid {
      display: grid;
      grid-row-gap: 16px;
      grid-template-columns: minmax(0, 1fr);

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}