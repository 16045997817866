.buttons-layout {
  > :nth-child(n) {
    margin-right: 0rem;
    margin-top: 8px;

    @media (max-width: 1200px) {
      margin-top: 0rem;
      margin-right: 8px;
    }
  }

  > :first-child {
    margin-top: 0rem;
  }

  > :last-child {
    @media (max-width: 1200px) {
      margin-right: 0rem;
    }
  }
}