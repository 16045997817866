
$gray-300: #f6f6f6;
$light: $gray-300;

.permission-box {
  border: 2px solid $light;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 4px;
}

.secondary-permission-box {
  margin-left: 32px;
}

.hevara-user-data {
  width: 100%;

  .tx-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    @media (min-width: 992px) {
      grid-template-columns: 2fr 1fr;
    }

    .detail-grid {
      display: grid;
      grid-row-gap: 16px;
      grid-template-columns: minmax(0, 1fr);

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}