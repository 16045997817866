/* OtpInput.css */

/* Styles for the container of OTP input fields */
.otp-input-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  /* Adjust the width as per your requirement */
}

/* Styles for the individual OTP input fields */
.otp-input-container input {
  width: 40px;
  height: 40px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

/* Style for the highlighted input field */
.otp-input-container input:focus {
  border-color: #007bff;
}

/* Style for the filled input fields */
.otp-input-container input:not(:placeholder-shown) {
  background-color: #f7f7f7;
}

/* Style for the invalid input fields */
.otp-input-container input:invalid {
  border-color: #dc3545;
  background-color: #fff0f3;
}