// 
// _badge.scss
// 


.badge {
  &[href] {
    &:hover,
    &:focus {
      color: var(--#{$prefix}secondary-bg);
    }
  }
}

// Soft badge
@mixin badge-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18) !important;

  &[href] {
    &:hover,
    &:focus {
      color: $bg;
      text-decoration: none;
      background-color: rgba($bg, 0.4);
    }
  }
}

@each $color, $value in $theme-colors {
  .badge {
  &.bg-#{$color} {
    &[href] {
      &:hover,
      &:focus {
        background-color: darken($value, 4%) !important;
      }
    }
  }
}

.badge{
  &.bg-light{
    color: var(--#{$prefix}body-color);

    &[href] {
      &:hover,
      &:focus {
        color: var(--#{$prefix}body-color);
      }
    }
  }
}

.badge-soft-#{$color} {
      @include badge-variant-soft($value);
  }
  
}

.rounded-pill{
   padding-right: .6em;
    padding-left: .6em;
}

// Dark badge

.badge.bg-dark{
  color: var(--#{$prefix}light);
}