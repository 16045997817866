// 
// Extras pages.scss
//


// pricing


.plan-box{
    .plan-btn{
        position: relative;

        &::before{
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background: var(--#{$prefix}border-color);
            left: 0px;
            right: 0px;
            top: 12px;
        }
    }
}

// blog

.blog-play-icon{
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    margin: 0px auto;
}

.logo-dark-element{
    display: block;
}

.logo-light-element{
    display: none;
}

[data-layout-mode="dark"]{
    .logo-dark-element{
        display: none;
    }
    
    .logo-light-element{
        display: block;
    }
}