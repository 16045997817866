.hevara-contact-list-item {
  display: grid;
  grid-template-columns: 2fr 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  .date-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    @media (min-width: 768px) {
      justify-content: space-between;
    }

    .title-and-date {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }

    .status-and-actions {
      display: flex;
      flex-direction: row;
    }
  }

  .contact-actions {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  .hevara-table-row-main0info-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .hevara-table-row-main-info {
    margin-right: 16px;

    &> :not(:first-child) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .reset-link:hover {
    text-decoration: none;
  }
}