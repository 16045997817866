.hevara-section-list {
  & > :not(:first-child) {
    margin-top: 16px
  }
  li.section-list-item:last-child {
    .hevara-divider {
      display: none
    }
  }
}

.reset-list-style {
  list-style: none;
  padding-left: 0;
}